<app-actionbar
  [showFilters]="true"
  (searchTextChange)="this.searchText = $event; this.searchTextChanged()"
  (mediaTypesChange)="this.mediaTypesSelected = $event; this.updateFilter()"
  (filterTypesChange)="this.filterTypesSelected = $event; this.updateFilter()"
  (showCompilationsChange)="this.showCompilations = $event; this.updateFilter()"
  (sortOrderChange)="this.sortOrder = $event; this.updateFilter()"
></app-actionbar>

<section class="content">
  <div class="entity-grid" id="main">
    <app-grid-element
      *ngFor="let element of filteredResults"
      class="grid-item"
      [element]="element"
      [quickAddToCollectionMenu]="quickAddToCollectionMenu"
      (updateSelectedObject)="selectObjectId = $event"
    ></app-grid-element>
  </div>

  <div class="paginator-container">
    <mat-paginator
      [length]="paginatorLength"
      [pageSize]="paginatorPageSize"
      [pageSizeOptions]="[30]"
      (page)="changePage($event)"
      [pageIndex]="paginatorPageIndex"
    >
    </mat-paginator>
  </div>
</section>

<mat-menu #quickAddToCollectionMenu="matMenu">
  <ng-container *ngIf="!(isAuthenticated$ | async)">
    <div style="padding: 0 2rem">
      <p>You have to login to create and modify collections.</p>
    </div>
  </ng-container>
  <ng-container *ngIf="isAuthenticated$ | async">
    <button class="quick-add-button" mat-button (click)="openCompilationWizard(selectObjectId)">
      <mat-icon color="primary">create_new_folder</mat-icon>
      <span>New collection with this object</span>
    </button>
    <ng-container *ngIf="userCompilations.length > 0">
      <button
        class="quick-add-button"
        *ngFor="let compilation of userCompilations"
        mat-button
        (click)="quickAddToCompilation(compilation)"
      >
        {{ compilation.name }}
      </button>
    </ng-container>
  </ng-container>
</mat-menu>
