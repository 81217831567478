<div class="content">
  <h1>Contact</h1>

  <p>Kompakkt is being developed at:</p>

  <p>
    <a href="http://dh.uni-koeln.de">Department of Digital Humanities</a><br />
    University of Cologne<br />
    Albertus-Magnus-Platz<br />
    50931 Köln<br /><br />
    <a href="mailto:info@kompakkt.de">info@kompakkt.de</a>
  </p>

  <h2>Kompakkt Team</h2>

  <p><a href="https://github.com/ZetOE"> Zoe Schubert </a></p>

  <p><a href="https://github.com/HeyItsBATMAN"> Kai Niebes </a></p>

  <p><a href="https://github.com/thundersnooze"> Slavina Stoyanova </a></p>

  <p><a href="https://github.com/NadNo12"> Nadjim Noori </a></p>

  <p><a href="https://github.com/janwieners"> Jan G. Wieners </a></p>

  <hr />

  <p>
    In the context of
    <a href="https://nfdi4culture.de/">NFDI4Culture</a>, a
    <a href="https://gitlab.com/nfdi4culture/ta1-data-enrichment/semantic-kompakkt">
      semantic version of Kompakkt
    </a>
    is being developed at:
  </p>

  <p>
    <a href="https://www.tib.eu/de/forschung-entwicklung/forschungsgruppen-und-labs/open-science">
      Open Science Lab
    </a>
    <br />
    TIB – Leibniz Information Centre for Science and Technology<br />
    Lange Laube 28<br />
    30159 Hannover<br /><br />
  </p>

  <h2>Semantic Kompakkt Team</h2>

  <p><a href="mailto:Ina.Bluemel@tib.eu"> Ina Blümel </a></p>

  <p><a href="https://gitlab.com/paulduchesne"> Paul Duchesne </a></p>

  <p><a href="https://gitlab.com/luguenth1"> Lukas Günther </a></p>

  <p><a href="https://gitlab.com/lozanaross"> Lozana Rossenova </a></p>

  <p><a href="https://gitlab.com/zetoheh"> Zoe Schubert </a></p>

  <p><a href="https://gitlab.com/sohmenl">Lucia Sohmen</a></p>

  <!-- <p><a href="https://github.com/richard-vock"> Richard Vock </a></p> -->
</div>
