<ng-container *ngIf="element !== undefined">
  <!-- Default behaviour: Navigate to element when clicked -->
  <ng-container *ngIf="!disableNavigationOnClick; else navigationDisabled">
    <ng-container *ngIf="isEntity(element); else showCompilation">
      <anim-img
        class="image cursor-pointer"
        [routerLink]="['/entity', element._id]"
        matTooltip="Click to view object"
        [matTooltipShowDelay]="1000"
        [src]="imageSource"
        [style.--bg-color]="backgroundColor"
        [alt]="element.name"
      ></anim-img>
    </ng-container>

    <ng-template #showCompilation>
      <div
        class="images cursor-pointer"
        [style.--bg-color]="backgroundColor"
        [class.single-image]="imageSources.length == 1"
        [routerLink]="['/compilation', element._id]"
        matTooltip="Click to view collection"
        [matTooltipShowDelay]="1000"
      >
        <anim-img
          [alt]="element.name"
          [src]="source"
          *ngFor="let source of imageSources"
        ></anim-img>
      </div>
    </ng-template>
  </ng-container>

  <div class="entity-type" *ngIf="!disableTypeInfo">
    <mat-icon
      (click)="openExploreDialog(element)"
      class="button cursor-pointer"
      [matTooltip]="'Show ' + (isEntity(element) ? 'object' : 'collection') + ' preview'"
      >open_in_browser</mat-icon
    >

    <mat-icon
      class="help cursor-default"
      *ngIf="tooltipContent.length > 20"
      [matTooltip]="tooltipContent"
      >description</mat-icon
    >

    <ng-container *ngIf="isCompilation(element)">
      <mat-icon
        class="help cursor-default"
        matTooltip="This collection has recently been annotated"
        *ngIf="isRecentlyAnnotated"
        >access_time</mat-icon
      >

      <mat-icon
        class="help cursor-default"
        *ngIf="isPasswordProtected"
        matTooltip="Password protected"
        >lock</mat-icon
      >

      <mat-icon class="cursor-default" [matTooltip]="collectionQuantityText">{{
        collectionQuantityIcon
      }}</mat-icon>

      <mat-icon class="help cursor-default" matTooltip="Collection">{{
        icons.collection
      }}</mat-icon>
    </ng-container>

    <ng-container *ngIf="isEntity(element)">
      <ng-container *ngIf="quickAddToCollectionMenu !== undefined">
        <mat-icon
          class="button cursor-pointer"
          matTooltip="Quick add to collection"
          [matMenuTriggerFor]="quickAddToCollectionMenu"
          (click)="selectObject(element._id)"
          >playlist_add</mat-icon
        >
      </ng-container>
      <mat-icon class="help cursor-default" [matTooltip]="mtype[mediaType]">{{
        icons[mediaType]
      }}</mat-icon>
    </ng-container>
  </div>

  <div class="title">
    <div>
      {{ element.name }}
    </div>
  </div>

  <!-- Optional behaviour: Ignore clicks on element (e.g. on profile page) -->
  <ng-template #navigationDisabled>
    <ng-container *ngIf="isEntity(element); else compilation">
      <anim-img
        class="image"
        [style.--bg-color]="backgroundColor"
        [src]="imageSource"
        alt="{{ element.name }}"
      ></anim-img>
    </ng-container>
    <ng-template #compilation>
      <div
        class="images"
        [style.--bg-color]="backgroundColor"
        [class.single-image]="imageSources.length == 1"
      >
        <anim-img
          alt="{{ element.name }}"
          *ngFor="let source of imageSources"
          [src]="source"
        ></anim-img>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
