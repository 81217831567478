<ng-container *ngIf="entity$ | async as entity">
  <!-- Entity -->

  <!-- DigitalEntity -->
  <ng-container *ngIf="digitalEntity$ | async as digitalEntity">
    <div class="block">
     <a href="{{ digitalEntity.wikibase_address }}/entity/{{ digitalEntity.wikibase_id }}" target="_blank"> <h1>{{ digitalEntity.label['en'] }}</h1></a>
      <p>{{ digitalEntity.description }}</p>
    </div>
    
    <div class="block">
      <app-detail-entity [digitalEntity]="digitalEntity"></app-detail-entity>
    </div>
  </ng-container>
  
</ng-container>
