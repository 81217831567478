<mat-toolbar id="navbar">
  <a id="logo-link" routerLink="/home">
    <img id="applogo" src="assets/semantic-kompakkt-logo.svg" alt="Kompakkt" />
  </a>

  <div class="desktop">
    <div class="nav-group">
      <a class="navbar-link" routerLinkActive="active" routerLink="/explore"
        >Explore</a
      >
      <a class="navbar-link" routerLinkActive="active" [routerLink]="annotateLink"
        > {{ annotationText }}</a>
      <!-- <a class="navbar-link" routerLinkActive="active" routerLink="/collaborate"
        >Collaborate</a
      > -->
    </div>
  </div>
  <div class="grow desktop end">
    <!-- Entity/Compilation creation -->
    <div *ngIf="isAuthenticated$ | async">
      <button *ngIf="!isUploader" mat-flat-button color="primary" (click)="openUploadApplication()">
        Request upload capabilities
      </button>
      <button *ngIf="uploadRequested()" mat-flat-button color="primary">
        Upload capabilities requested
      </button>
      <ng-container *ngIf="isUploader">
        <button mat-flat-button color="primary" id="tib-logo" (click)="openEntityCreation()">
          <mat-icon>insert_drive_file</mat-icon>
          New Object
        </button>
        <!-- <button mat-flat-button color="primary" (click)="openCompilationCreation()">
          <mat-icon>collections</mat-icon>
          New Collection
        </button> -->
      </ng-container>
    </div>
    <!-- View Selection History -->
    <div *ngIf="selectHistory.history.length > 0">
      <button
              matTooltip="View your history."
              [disabled]="selectHistory.history.length === 0"
              mat-icon-button
              [matMenuTriggerFor]="selectionHistory"
      >
        <mat-icon>history</mat-icon>
      </button>
    </div>
    <div class="nav-group">
      <ng-container
        *ngIf="!(isAuthenticated$ | async); else authenticatedBlock"
      >
        <a class="navbar-link" id="login" (click)="openLoginDialog()">
          Login
        </a>
        <a class="navbar-link" id="register" (click)="openRegisterDialog()">Register</a>
      </ng-container>
      <ng-template #authenticatedBlock>
        <a
          *ngIf="isAdmin$ | async"
          class="navbar-link"
          routerLinkActive="active"
          routerLink="/admin"
          >Admin</a
        >
        <a class="navbar-link" routerLinkActive="active" routerLink="/profile"
          >Profile</a
        >
        <a class="navbar-link" id="logout" (click)="logout()">Logout</a>
      </ng-template>
    </div>
  </div>

  <div class="grow mobile end">
    <button id="sidenav-toggle" mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-menu #selectionHistory="matMenu">
  <button
          class="history-button"
          *ngFor="let element of selectHistory.history"
          mat-menu-item
          (click)="navigate(element)"
  >
    <mat-icon *ngIf="isEntity(element)" matTooltip="View object."> insert_drive_file </mat-icon>
    <mat-icon *ngIf="isCompilation(element)" matTooltip="View compilation.">
      folder_special
    </mat-icon>
    <p>{{ element.name }}</p>
  </button>
</mat-menu>
