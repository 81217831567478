<div class="content">
  <h1>Privacy Policy</h1>

  <p>
    We take the protection of your personal data very seriously. We want you to know when we store
    which data and how we use it when you visit kompakkt.de. Personal data are collected on this
    server only to the necessary extent. Under no circumstances will the data collected be sold or
    passed on to third parties for any reason. The following statement in accordance with Chapter 13
    of the General Data Protection Regulation (GDPR) gives you an overview of how we guarantee this
    protection and what kinds of data are collected for what purposes.
  </p>

  <h2>Contact</h2>

  <p>Responsible for the processing of personal data for the operation of this website:</p>

  <p>
    Open Science Lab <br />
    TIB – Leibniz Information Centre for Science and Technology<br />
    Lange Laube 28<br />
    30159 Hannover<br />
    Ina Blümel, <a href="mailto:Ina.Bluemel@tib.eu">Ina.Bluemel@tib.eu</a><br />
    Lozana Rossenova, <a href="mailto:Lozana.Rossenova@tib.eu">Lozana.Rossenova@tib.eu</a><br />
    <a href="https://www.tib.eu/en/research-development/research-groups-and-labs/open-science">
      https://www.tib.eu/en/research-development/research-groups-and-labs/open-science
    </a>
  </p>

  <h2>Purposes, duration, and legal basis of the processing of personal data</h2>

  <h3>Web protocols of the WWW server</h3>

  <p>
    Semantic Kompakkt collects and automatically stores information your browser sends in the protocol files
    of the web server. This includes:
  </p>
  <ul>
    <li>browser type and version</li>
    <li>computer name or IP address of the accessing computer</li>
    <li>time of the request to the server</li>
    <li>requested website</li>
  </ul>
  <p>
    The temporary storage of the IP address by the system is necessary to enable the website to be
    delivered to your computer. To do this, the IP address of your computer must remain stored for
    the duration of the session.
    <br />
    In addition, the data helps us to ensure the security of our information technology systems and
    to optimize our website. An evaluation of the data for marketing purposes does not take place in
    this context.
    <br />
    Of the above data, the IP address is considered to be personal. Your IP address will be stored
    for a maximum of 7 days and then completely anonymized (deleted or alienated). After that time,
    the above data is no longer personal. It shall be deleted as soon as it is no longer necessary
    to achieve the purpose for which it was collected
  </p>

  <h3>Matomo</h3>
  <p>
    This website uses Matomo (previously Piwik), a software that statistically analyses user access.
    Matomo does not use tracking cookies, so (cf. <a
    href="https://matomo.org/faq/new-to-piwik/how-do-i-use-matomo-analytics-without-consent-or-cookie-banner/">Matomo's
    official documentation</a>):
  </p>
  <ul>
    <li>"the data is not used for any other purpose than analytics"</li>
    <li>"visitors aren't tracked across websites"</li>
    <li>"fingerprints change daily for every visitor meaning no visitor can be followed across days within the same
      website, and no user profiles can be generated when cookies are disabled"</li>
  </ul>
  <p>
  Your IP address will be made anonymous immediately after processing and before it is stored.
  More information on the Matomo software’s privacy settings can be found here:
  https://matomo.org/docs/privacy/.
  </p>

  <h2>Your rights</h2>

  <p>
    Every person affected by the processing of personal data has the right, in accordance with the
    legal provisions, to request free information or confirmation from us regarding the personal
    data stored about him or her.<br />

    In addition, you have the right to the immediate correction or deletion of incorrect personal
    data concerning you, or to the restriction of the processing, or to object to the processing.
    (However, the collection of data for the provision of the website and the storage of data in log
    files is necessary for the operation of the website. Consequently, there is no possibility of
    objection in this respect.).<br />

    You can revoke your consent to the processing of personal data at any time. This does not affect
    the legality of the Website’s processing of your data on the basis of your consent until your
    revocation. Data subjects have the right to request information regarding their personal data in
    a structured, common and machine-readable format and to transmit it to other persons
    responsible.<br />

    We are happy to answer your questions regarding the processing of your personal data at any
    time. If you would like to know which personal data we store about you or your pseudonym, or if
    you have questions that this data protection statement could not answer, or if you would like
    more detailed information on a particular point, please contact us.
  </p>
</div>
