<div>
  <p><b>Public</b> objects are visible for all users on the "Explore" page.</p>
  <p>
    <b>Restricted</b> objects are only findable by users and/or groups which have been assigned to
    the object during or after object creation. You can edit object restrictions at any point.
  </p>
  <p><b>Private</b> objects are accessible only on your profile page.</p>
  <p>
    <b>Unfinished</b> objects are any objects which did not finish the upload process due to either
    settings inside the viewer not being set, file upload not being started or completed or metadata
    form being invalid or incomplete. You can continue working on unfinished objects from here.
  </p>
</div>
