<form [formGroup]="form" (submit)="trySubmit()">
  <h3>Choose a new password</h3>
  <mat-form-field class="fullwidth">
    <input
      matInput
      placeholder="Username"
      type="text"
      autocomplete="username"
      name="username"
      formControlName="username"
      required
    />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <input
      matInput
      placeholder="New password"
      type="password"
      autocomplete="new-password"
      name="password"
      formControlName="password"
      required
    />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <input
      matInput
      placeholder="Repeat new password"
      type="password"
      autocomplete="new-password"
      name="password-repeat"
      formControlName="passwordRepeat"
      required
    />
  </mat-form-field>
  <div class="bottom-row">
    <span class="errors">
      <ng-container *ngIf="form.errors?.not_equal"> Passwords are not equal </ng-container>
      <ng-container *ngIf="serverErrorMsg.length > 0">{{ serverErrorMsg }}</ng-container>
    </span>
    <button mat-stroked-button color="accent" type="submit" [disabled]="!form.valid">
      Change your password
    </button>
  </div>
</form>
