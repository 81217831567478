import { Component, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { isDigitalEntity, IEntity, IDigitalEntity } from 'src/common';
import { AccountService, SnackbarService, ClipboardService } from 'src/app/services';

@Component({
  selector: 'app-entity-detail',
  templateUrl: './entity-detail.component.html',
  styleUrls: ['./entity-detail.component.scss'],
})
export class EntityDetailComponent implements AfterViewInit, OnChanges {
  @Input('entity')
  public entity: IEntity | undefined;

  private entitySubject = new BehaviorSubject<IEntity | undefined>(undefined);

  constructor(
    public account: AccountService,
    private clipboard: ClipboardService,
    private snackbar: SnackbarService,
  ) {}

  get entity$() {
    return this.entitySubject.asObservable();
  }

  get digitalEntity$() {
    return this.entity$.pipe(
      map(entity => entity?.relatedDigitalEntity),
      filter(digitalEntity => isDigitalEntity(digitalEntity)),
      map(digitalEntity => digitalEntity as IDigitalEntity),
    );
  }

  // get physicalEntites$() {
  //   return this.digitalEntity$.pipe(map(digitalEntity => digitalEntity.phyObjs));
  // }

  public copyId() {
    const _id = this.entitySubject.value?._id;
    if (!_id) return this.snackbar.showMessage('Could not copy id');
    this.clipboard.copy(_id.toString());
  }

  ngAfterViewInit() {
    // Workaround for https://github.com/angular/components/issues/11478
    const interval = setInterval(
      () => document.querySelectorAll('mat-tooltip-component').forEach(item => item.remove()),
      50,
    );

    setTimeout(() => clearInterval(interval), 500);
  }

  ngOnChanges(changes: SimpleChanges) {
    const entity = changes.entity?.currentValue as IEntity | undefined;
    if (entity) this.entitySubject.next(entity);
  }
}
