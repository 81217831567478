import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AccountService, BackendService } from 'src/app/services';

@Component({
  selector: 'app-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss'],
})
export class RegisterDialogComponent {
  public error = '';

  public form = new FormGroup({
    prename: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required),
    mail: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    passwordRepeat: new FormControl('', Validators.required),
  });

  public waitingForResponse = false;

  constructor(
    private backend: BackendService,
    public dialogRef: MatDialogRef<RegisterDialogComponent>,
    private account: AccountService,
  ) { }

  public async trySubmit() {
    this.error = '';
    const { username, password, prename, surname, passwordRepeat, mail } = this.form.value;
    if (!username || !password) {
      this.error = 'Missing username or password';
      return;
    }

    if (password !== passwordRepeat) {
      this.error = 'Passwords do not match';
      return;
    }

    this.waitingForResponse = true;
    this.dialogRef.disableClose = true;

    // Destructure and omit passwordRepeat
    const registerData = { username, password, prename, surname, mail, fullname: `${prename} ${surname}` };

    const registerSuccess = await this.backend
      .registerAccount(registerData)
      .catch((err) => {
        console.log('Error w/ catch', err);
        this.error = err.error || err.message;
        this.waitingForResponse = false;
        this.dialogRef.disableClose = false;
        return null;
      })
      .finally(() => {
        this.waitingForResponse = false;
        this.dialogRef.disableClose = false;
      });

    const userdata = await this.account.loginOrFetch({ username, password });

    this.dialogRef.disableClose = false;
    this.waitingForResponse = false;
    if (!userdata) return;

    this.dialogRef.close({ username, password });
  }
}
