import { Component, EventEmitter, AfterViewInit, Output, ViewChild, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatProgressBar } from '@angular/material/progress-bar';
import {
  AccountService,
  ProgressBarService,
  DialogHelperService,
  EventsService,
  SelectHistoryService,
  BackendService,
  AllowAnnotatingService,
} from 'src/app/services';
import { UserRank } from "~common/enums";
import {
  ICompilation,
  IEntity,
  IUserData,
  isEntity,
  isCompilation,
} from 'src/common';
import { ConfirmationDialogComponent, UploadApplicationDialogComponent } from "~dialogs";
import { MatDialog } from "@angular/material/dialog";
import { AddEntityWizardComponent } from "~wizards";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements AfterViewInit, OnInit {
  @Output() public sidenavToggle = new EventEmitter();

  public element: IEntity | ICompilation | undefined = undefined;
  public userData: IUserData | undefined;
  public isEntity = isEntity;
  public isCompilation = isCompilation;
  public annotationText = "Annotation Demo";

  @ViewChild('progressBar')
  private progressBar: undefined | MatProgressBar;

  constructor(
    private account: AccountService,
    private progress: ProgressBarService,
    private dialog: MatDialog,
    private dialogHelper: DialogHelperService,
    private router: Router,
    private events: EventsService,
    private backend: BackendService,
    public selectHistory: SelectHistoryService,
    private annotatingService: AllowAnnotatingService,
  ) {
    this.account.userData$.subscribe(newData => {
      if (!newData) return;
      this.userData = newData;
    });
    this.router.events.subscribe(() => {
      // if we entity is in route
      if (this.router.url.includes('entity')) {
        const id = this.router.url.split("/").pop();
        if (this.element === undefined && id) {
          this.backend.getEntity(id).then(entity => {
            this.element = entity;
            this.checkAnnotation();
          });
        }
      } else {
        this.element = undefined;
        this.checkAnnotation();
      }
    });
  }

  public checkAnnotation() {
    if (this.element) {
      this.annotationText = this.annotatingService.isUserOwner(this.element) ? "Annotation" : "Annotation Demo";
      return true;
    } else {
      this.annotationText = "Annotation Demo";
      return false;
    }
  }

  get isAuthenticated$() {
    return this.account.isAuthenticated$;
  }

  get isUploader() {
    return this.userData?.role === UserRank.admin || this.userData?.role === UserRank.uploader;
  }


  get isAdmin$() {
    return this.account.isAdmin$;
  }

  get allowAnnotating() {
    if (!this.element) return false;
    return this.annotatingService.isUserOwner(this.element);
  }

  public ngOnInit() {
    const id = window.location.href.split("/").pop();
    if (!id) return false;

    this.backend.getEntity(id).then(entity => {
      if (!entity) return false;
      this.element = entity;
    });
  }

  ngAfterViewInit() {
    if (this.progressBar) {
      this.progress.setProgressBar(this.progressBar);
    }
  }

  public logout() {
    this.account.logout().then(() => this.router.navigate(['/']));
  }

  public onToggleSidenav() {
    this.sidenavToggle.emit();
  }

  public openLoginDialog() {
    this.dialogHelper.openLoginDialog();
  }

  public openRegisterDialog() {
    this.dialogHelper.openRegisterDialog();
  }

  public openUploadApplication() {
    if (!this.userData) {
      alert('Not logged in');
      return;
    }
    const dialogRef = this.dialog.open(UploadApplicationDialogComponent, {
      data: this.userData,
      disableClose: true,
    });

    dialogRef.backdropClick().subscribe(async () => {
      const confirm = this.dialog.open(ConfirmationDialogComponent, {
        data: 'Do you want to cancel your application?',
      });
      await confirm
        .afterClosed()
        .toPromise()
        .then(shouldClose => {
          if (shouldClose) {
            dialogRef.close();
          }
        });
    });
  }

  public uploadRequested() {
    if (!this.userData) return false;
    return this.userData.role === UserRank.uploadrequested;
  }

  public async openEntityCreation(entity?: IEntity) {
    const dialogRef = this.dialog.open(AddEntityWizardComponent, {
      data: entity ? entity : undefined,
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => {
        this.events.updateSearchEvent();
        if (result && this.userData && this.userData.data.entity) {
          const index = (this.userData.data.entity as IEntity[]).findIndex(
            _en => result._id === _en._id,
          );
          if (index === -1) return;
          this.userData.data.entity.splice(index, 1, result as IEntity);
          // this.updateFilter();
        }
      });
  }

  public async openCompilationCreation(compilation?: ICompilation) {
    const dialogRef = this.dialogHelper.openCompilationWizard(compilation);
    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => {
        this.events.updateSearchEvent();
        if (result && this.userData && this.userData.data.compilation) {
          if (compilation) {
            const index = (this.userData.data.compilation as ICompilation[]).findIndex(
              comp => comp._id === result._id,
            );
            if (index === -1) return;
            this.userData.data.compilation.splice(index, 1, result as ICompilation);
          } else {
            (this.userData.data.compilation as ICompilation[]).push(result as ICompilation);
          }
        }
      });
  }

  get annotateLink() {
    return this.element
      ? isEntity(this.element) && this.checkAnnotation()
        ? ['/annotate', 'entity', this.element._id]
        : ['/annotate', 'compilation', this.element._id]
      : ['/annotate'];
  }

  public navigate(element: IEntity | ICompilation) {
    // Parent will load and fetch relevant data
    this.element = undefined;

    return this.router.navigateByUrl(
      `/${isEntity(element) ? 'entity' : 'compilation'}/${element._id}`,
    );
  }
}
