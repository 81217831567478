import { AfterViewInit, Component } from '@angular/core';
import { BackendService } from '~services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterViewInit {

  public workshopMode = false;

  constructor(
    private backend: BackendService
  ) { }

  ngAfterViewInit(): void {
    this.backend.getWorkshopMode().then((result) => {
      this.workshopMode = result;
    });
  }
}
