<div [class.content]="!this.dialogRef" id="container"
     [style.--max-width]="maxWidth"
>
  <div class="dialog-title-wrapper">
    <h3 class="dialog-title">Edit an Object</h3>

    <button
    *ngIf="dialogRef"
    mat-icon-button
    class="close-dialog-button"
    (click)="dialogRef.close(undefined)"
    tabindex="-1"
    >
    <mat-icon>close</mat-icon>
  </button>
  </div>

  <ng-container *ngIf="!(isAuthenticated$ | async)">
    <div class="metadata-nested">
      <h3>Only available for logged in Semantic Kompakkt users.</h3>
    </div>
  </ng-container>

  <mat-horizontal-stepper
    [linear]="true"
    #stepper
    (selectionChange)="stepInteraction($event)"
    *ngIf="isAuthenticated$ | async"
  >
    <mat-step
      *ngIf="!this.dialogRef || !this.dialogData"
      label="Upload"
      [completed]="externalFileValid || (uploadValid$ | async)"
      #stepUpload
    >
      <div class="can-be-disabled" [class.disabled]="externalFileControl.value !== ''">
        <app-upload></app-upload>
      </div>

      <mat-divider></mat-divider>

      <div class="external-file-input can-be-disabled" [class.disabled]="!(isQueueEmpty$ | async)">
        <h3>Load your file from an external server</h3>
        <p>This can only be a single file. See recommended files from above</p>
        <mat-form-field>
          <mat-label>External File</mat-label>
          <input matInput placeholder="URL of single file" [formControl]="externalFileControl" />
          <mat-error *ngIf="externalFileControl.hasError('nourl')">
            This does not look like a URL
          </mat-error>
          <mat-error *ngIf="externalFileControl.hasError('unsupported')">
            File format not supported for external files
          </mat-error>
          <mat-error *ngIf="externalFileControl.hasError('unsafe')">
            File is not hosted on a secure server
          </mat-error>
        </mat-form-field>
      </div>

      <div class="wizard-step-buttons" *ngIf="!externalFileValid; else externalFileBlock">
        <button
          mat-stroked-button
          color="warn"
          [disabled]="!(canCancel$ | async)"
          (click)="uploadHandler.resetQueue()"
        >
          Reset upload
        </button>
        <button
          id="begin-upload"
          mat-stroked-button
          color="primary"
          [disabled]="!(canBeginUpload$ | async)"
          *ngIf="showBeginUpload$ | async"
          (click)="uploadHandler.startUpload()"
        >
          Begin upload
        </button>
        <button
          id="next-upload"
          mat-stroked-button
          color="primary"
          *ngIf="showNext$ | async"
          (click)="uploadBaseEntity(stepper)"
          [disabled]="!(uploadValid$ | async)"
        >
          Next
        </button>
      </div>

      <ng-template #externalFileBlock>
        <div class="wizard-step-buttons single">
          <button mat-stroked-button color="primary" (click)="uploadBaseEntity(stepper)">
            Continue using external file
          </button>
        </div>
      </ng-template>
    </mat-step>
    <mat-step
      *ngIf="!(serverEntityFinished$ | async) || !(settingsValid$ | async)"
      label="Settings"
      #stepSettings
      [completed]="settingsValid$ | async"
    >
      <iframe
        id="viewer-frame"
        #babylonPreview
        *ngIf="viewerUrl"
        [src]="viewerUrl"
        allowfullscreen
      ></iframe>

      <div class="wizard-step-buttons">
        <button mat-stroked-button matStepperPrevious [disabled]="!stepUpload">Back</button>
        <button
          mat-stroked-button
          [disabled]="!(settingsValid$ | async)"
          color="primary"
          matStepperNext
        >
          Next
        </button>
      </div>
    </mat-step>
    <mat-step
      label="Metadata"
      #stepMetadata
      [completed]="(digitalEntityValid$ | async)" 
    ><!-- && stepMetadata.interacted" TODO: We need to reimplement this somehow -->
      <ng-container *ngIf="digitalEntity$ | async as digitalEntity">
        <mat-sidenav-container class="metadata-container">
          <mat-sidenav mode="side" opened class="metadata-nav">
              <mat-action-list>
                <button mat-list-item (click)="metaDataIndex = 0" [ngClass]="metaDataIndex === 0 ? 'metaActive' : ''"><mat-icon class="sidenav-link" [color]="digitalEntity.checkValidGeneralInfo() ? 'primary' : 'warn'">label_important</mat-icon> General Information </button>
                <button mat-list-item (click)="metaDataIndex = 1" [ngClass]="metaDataIndex === 1 ? 'metaActive' : ''"><mat-icon class="sidenav-link" [color]="digitalEntity.checkValidRelatedAgents() ? 'primary' : 'warn'">label_important</mat-icon> Related Agents </button>
                <button mat-list-item (click)="metaDataIndex = 2" [ngClass]="metaDataIndex === 2 ? 'metaActive' : ''"><mat-icon class="sidenav-link" [color]="digitalEntity.checkValidLicence() ? 'primary' : 'warn'">label_important</mat-icon> License </button>
                <button mat-list-item (click)="metaDataIndex = 3" [ngClass]="metaDataIndex === 3 ? 'metaActive' : ''"><mat-icon class="sidenav-link" color="primary">label</mat-icon> Creation </button>
                <button mat-list-item (click)="metaDataIndex = 4" [ngClass]="metaDataIndex === 4 ? 'metaActive' : ''"><mat-icon class="sidenav-link" color="primary">label</mat-icon> External Links </button>
                <button mat-list-item (click)="metaDataIndex = 5" [ngClass]="metaDataIndex === 5 ? 'metaActive' : ''"><mat-icon class="sidenav-link" color="primary">label</mat-icon> Bibliographic References </button>
                <button mat-list-item (click)="metaDataIndex = 6" [ngClass]="metaDataIndex === 6 ? 'metaActive' : ''"><mat-icon class="sidenav-link" color="primary">label</mat-icon> Related Physical Objects </button>
              </mat-action-list>
          </mat-sidenav>
          <mat-sidenav-content>
            <app-entity [(index)]="metaDataIndex" [digitalEntity]="digitalEntity"></app-entity>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </ng-container>


      <div class="wizard-step-buttons">
        <button mat-stroked-button matStepperPrevious>Back</button>
        <button
          (click)="updateDigitalEntity()"
          mat-stroked-button
          color="primary"
          matStepperNext
          [disabled]="!(digitalEntityValid$ | async)"
        >
          Next
        </button>
      </div>
    </mat-step>
    <mat-step label="Finalize" #stepFinalize [completed]="isFinished && stepFinalize.interacted">
      <h1>Finalize upload</h1>

      <p>
        You're almost done. Please take time to see if you entered everything correctly. If you
        think everything is correct, press the "Finish" button on the bottom of the wizard and we
        will check for invalid content.
      </p>

      <ng-container *ngIf="!this.dialogData">
        <ng-container *ngIf="entitySettings$ | async as settings">
          <h2>Settings</h2>
          <ng-container *ngIf="settingsValid$ | async; else noSettings">
            Your settings for this object are safe! Here's your preview image:
            <anim-img [src]="settings.preview"></anim-img>
          </ng-container>
          <ng-template #noSettings>
            <p>
              You did not set any settings for this object. You can set object settings on the first
              page of this wizard inside the viewer.
            </p>
            <button mat-raised-button color="primary" (click)="stepper.selectedIndex = 0">
              <mat-icon>thumb_up</mat-icon>
              Take me to the settings
            </button>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!this.dialogData">
        <h2>Files</h2>
        <ng-container *ngIf="uploadValid$ | async; else noUpload">
          <app-upload [preview]="true"></app-upload>
        </ng-container>
        <ng-template #noUpload>
          <p>You have not uploaded any files yet.</p>
          <button mat-raised-button color="primary" (click)="stepper.selectedIndex = 0">
            <mat-icon>thumb_up</mat-icon>
            Take me to the upload
          </button>
        </ng-template>
      </ng-container>

      <h2>Metadata:</h2>
      <div *ngIf="digitalEntity$ | async as entity; else noDigitalEntity" class="metadata-overview">
        <div class="row">
          <mat-icon>description</mat-icon>
          <div>
            <p>{{ entity.label['en'] ?? '' }}</p>

            <p>{{ entity.description }}</p>
          </div>
        </div>

        <div class="row">
          <mat-icon>people</mat-icon>
          <div>
            <p *ngFor="let agent of entity.agents">{{ agent.label['en'] }}</p>
          </div>
        </div>
      </div>
      <ng-template #noDigitalEntity>
        <p>You have not filled all required fields in the metadata form.</p>
        <button mat-raised-button color="primary" (click)="stepper.selectedIndex = 1">
          <mat-icon>thumb_up</mat-icon>
          Take me to the metadata form
        </button>
      </ng-template>

      <!-- If we havent sent data to the server, display option to do so -->
      <div class="wizard-step-buttons" *ngIf="!isFinished">
        <button mat-stroked-button [disabled]="isFinishing" matStepperPrevious>Back</button>
        <button
          mat-stroked-button
          *ngIf="(canFinish$ | async)"
          [disabled]="!(canFinish$ | async) || isFinishing || isFinished"
          color="primary"
          (click)="tryFinish(stepper, stepFinalize)"
        >
          <mat-spinner *ngIf="isFinishing" diameter="20"></mat-spinner>
          <span *ngIf="!isFinishing">Finish</span>
          <span *ngIf="isFinished">Finished</span>
          <span *ngIf="isFinishing">Finishing...</span>
        </button>
        <!-- show spinner if canFinish is false -->
        <button
          mat-stroked-button
          *ngIf="!(canFinish$ | async)"
          color="primary"
          [disabled]="true"
          >
          <mat-spinner diameter="20"></mat-spinner>
          <span> Object is still uploading...</span>
        </button>
      </div>
      <!-- if errorOnFinish is set, display error message -->
      <div class="wizard-step-buttons single" *ngIf="errorOnFinish">
        <p class="error">Error: {{ errorOnFinish }}</p>
      </div>
      <!-- Otherwise display button to progress -->
      <div class="wizard-step-buttons single" *ngIf="isFinished">
        <button mat-stroked-button matStepperNext color="primary">Next</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
