<div class="content">
  <ng-container *ngIf="!(isAdmin$ | async); else adminBlock">
    <h1>User Administration</h1>
    <h2>Waiting for admin data</h2>
  </ng-container>
  <ng-template #adminBlock>
    <h1>User Administration</h1>
    <ng-container *ngIf="users.length > 0">
      <mat-form-field>
        <input type="text" matInput placeholder="Select a user" [matAutocomplete]="userAutoComplete"
          (input)="changeSearchInput($event)" />
      </mat-form-field>

      <mat-autocomplete #userAutoComplete="matAutocomplete" [displayWith]="displayName"
        (optionSelected)="userSelected($event)">
        <mat-option *ngFor="let user of autocompleteUsers" [value]="user">{{
          user.fullname
          }}</mat-option>
      </mat-autocomplete>

      <ng-container *ngIf="selectedUser">
        <h2>Showing information about {{ selectedUser.fullname }}</h2>
        <h4>Full name: {{ selectedUser.fullname }}</h4>

        <span id="role-field">
          <span>Role: {{ selectedUser.role }}</span>
          <mat-form-field>
            <mat-select [(value)]="selectedRole">
              <mat-option value="user">User</mat-option>
              <mat-option value="uploader">Uploader</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button (click)="updateUserRole()">
            Change user role
          </button>
        </span>

        <h4>Username: {{ selectedUser.username }}</h4>
        <h4>Mail: {{ selectedUser.mail }}</h4>
        <h4>ID: {{ selectedUser._id }}</h4>

        <mat-tab-group>
          <mat-tab label="Objects">
            <h4>Showing {{ entities.length }} objects</h4>
            <div class="grid">
              <div class="grid-element" *ngFor="let entity of entities">
                <h6>Title: {{ entity.name }}</h6>
                <h6>Type: {{ entity.mediaType }}</h6>
                <h6>Finished: {{ entity.finished }}</h6>
                <h6>Online: {{ entity.online }}</h6>
                <h6>ID: {{ entity._id }}</h6>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Collections">
            <h4>Showing {{ compilations.length }} objects</h4>
            <div class="grid">
              <div class="grid-element" *ngFor="let compilation of compilations">
                <h6>Title: {{ compilation.name }}</h6>
                <h6>Description: {{ compilation.description }}</h6>
                <h6># of objects: {{ compilation.entities.length }}</h6>
                <h6># of annotations: {{ compilation.annotations.length }}</h6>
                <h6>Whitelist enabled: {{ compilation.whitelist.enabled }}</h6>
                <h6>
                  # of whitelisted persons:
                  {{ compilation.whitelist.groups.length }}
                </h6>
                <h6>
                  # of whitelisted groups:
                  {{ compilation.whitelist.persons.length }}
                </h6>
                <h6>ID: {{ compilation._id }}</h6>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Tags">
            <h4>Showing {{ tags.length }} objects</h4>
            <div class="grid">
              <div class="grid-element" *ngFor="let tag of tags">
                <h6>Value: {{ tag.value }}</h6>
                <h6>ID: {{ tag._id }}</h6>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Agents">
            <h4>Showing {{ agents.length }} objects</h4>
            <div class="grid">
              <div class="grid-element" *ngFor="let agent of agents">
                <h6>Name: {{ agent.label['en'] }}</h6>
                <h6>ID: {{ agent.id }}</h6>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Annotations">
            <h4>Showing {{ annotations.length }} objects</h4>
            <div class="grid">
              <div class="grid-element" *ngFor="let annotation of annotations">
                <h6>Title: {{ annotation.body.content.title }}</h6>
                <h6>Description: {{ annotation.body.content.description }}</h6>
                <h6>
                  Target object: {{ annotation.target.source.relatedEntity }}
                </h6>
                <h6>
                  Target collection:
                  {{ annotation.target.source.relatedCompilation }}
                </h6>
                <h6>Validated: {{ annotation.validated }}</h6>
                <h6>Ranking: {{ annotation.ranking }}</h6>
                <h6>ID: {{ annotation._id }}</h6>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Groups">
            <h4>Showing {{ groups.length }} objects</h4>
            <div class="grid">
              <div class="grid-element" *ngFor="let group of groups">
                <h6>Name: {{ group.name }}</h6>
                <h6># of members: {{ group.members.length }}</h6>
                <h6># of owners: {{ group.owners.length }}</h6>
                <h6>ID: {{ group._id }}</h6>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Metadata">
            <h4>Showing {{ metadata.length }} objects</h4>
            <div class="grid">
              <div class="grid-element" *ngFor="let data of metadata">
                <h6>Title: {{ data.label['en'] }}</h6>
                <h6>Description: {{ data.description }}</h6>
                <h6>ID: {{ data._id }}</h6>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
    </ng-container>
    <h1> Workshop Settings </h1>
    <!-- Activate Workshop mode. toggle to activate the workshop mode. This enables all users to have upload rights by default while this mode is active. -->
    <mat-checkbox (change)="toggleWorkshopMode()" [checked]="workshopMode">
      Activate Workshop mode
    </mat-checkbox>
    <p>
      This will enable all users to have upload rights by default while this
      mode is active.
    </p>

    <h1>Repair Annotations</h1>
    <p>
      This will repair all annotations that are not linked to a compilation or
      entity.
    </p>
    <mat-checkbox (change)="repairAnnotationsCheckbox = !repairAnnotationsCheckbox">
      I understand that this can cause old annotations to show up again.
    </mat-checkbox>
    <button class="repair-button" mat-raised-button (click)="repairAnnotations()"
      [disabled]="!repairAnnotationsCheckbox">
      Repair Annotations
    </button>
    <mat-card class="serverAnswer" *ngIf="checked">
      <mat-card-title> Repaired Annotations! </mat-card-title>
      <mat-card-content>
        <p>
          {{ serverKeys.length }} annotations were repaired. The following
          annotations were repaired:
        </p>
        <mat-card *ngFor="let key of serverKeys">
          <mat-card-title>
            <a [routerLink]="['/entity', key]" target="_blank">
              {{ key }}
            </a>
          </mat-card-title>
          <mat-card-content>
            <mat-chip-list>
              <mat-chip *ngFor="let value of serverAnswer[key]">
                {{ value }}
              </mat-chip>
            </mat-chip-list>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-card>

  </ng-template>

</div>