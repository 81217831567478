<form [formGroup]="form" (submit)="trySubmit()">
  <h3>Register</h3>
  <mat-form-field class="fullwidth">
    <input
      name="mail"
      autocomplete="email"
      placeholder="E-Mail address"
      type="mail"
      matInput
      formControlName="mail"
    />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <input
      name="prename"
      autocomplete="given-name"
      placeholder="Given name"
      type="text"
      matInput
      formControlName="prename"
    />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <input
      name="surname"
      autocomplete="last-name"
      placeholder="Last name"
      type="text"
      matInput
      formControlName="surname"
    />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <input
      name="username"
      autocomplete="username"
      placeholder="Username"
      type="text"
      matInput
      formControlName="username"
    />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <input
      name="password"
      autocomplete="new-password"
      placeholder="Password"
      type="password"
      matInput
      formControlName="password"
    />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <input
      name="password"
      autocomplete="new-password"
      placeholder="Repeat password"
      type="password"
      matInput
      formControlName="passwordRepeat"
    />
  </mat-form-field>

  <div class="button-row">
    <button
      mat-stroked-button
      (click)="dialogRef.close('cancel')"
      color="warn"
      [disabled]="waitingForResponse"
    >
      Cancel
    </button>
   <button
     mat-stroked-button
     type="submit"
     color="primary"
     [disabled]="!form.valid || waitingForResponse"
   >
     Create new account
   </button>
  </div>
</form>

<div class="errors" *ngIf="error">{{ error }}</div>
