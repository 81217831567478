<mat-toolbar id="actionbar">
  <!-- Login/Registration -->
  <!-- <div *ngIf="!(isAuthenticated$ | async)">
    <button
      mat-flat-button
      color="primary"
      (click)="openLoginDialog()"
      matTooltip="Login to your Semantic Kompakkt account."
    >
      <mat-icon>perm_identity</mat-icon>
      Login
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="openRegisterDialog()"
      matTooltip=s"Create a new account."
    >
      <mat-icon>how_to_reg</mat-icon>
      Register
    </button>
  </div> -->
  <!-- Filtering -->
  <div *ngIf="showFilters" class="container">
    <form>
      <mat-form-field>
        <input
          matInput
          placeholder="Search in Objects & Compilations."
          name="search-text"
          [(ngModel)]="searchText"
          (ngModelChange)="searchTextChanged()"
        />
      </mat-form-field>
    </form>
    <!--div>
      Objects
      <mat-slide-toggle
        color="primary"
        [checked]="showCompilations"
        (change)="toggleSlide()"
        [matTooltip]="'Click to switch to ' + (showCompilations ? 'Objects' : 'Collections')"
      >
      </mat-slide-toggle>
      Collections
    </div-->
    <mat-form-field *ngIf="!showCompilations">
      <mat-label>Media Types</mat-label>
      <mat-select
        multiple
        [formControl]="mediaTypesSelected"
        [value]="mediaTypesSelected"
        matTooltip="Semantic Kompakkt supports more than just 3D models. Select what kind of media you'd like to see."
        (selectionChange)="updateMediaTypeOptions($event)"
      >
        <mat-option *ngFor="let option of mediaTypesOptions" [value]="option.value"
          >{{ option.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Filter Options</mat-label>
      <mat-select
        multiple
        [formControl]="filterTypesSelected"
        [value]="filterTypesSelected"
        matTooltip="Filter the results"
        (selectionChange)="updateFilterTypeOptions($event)"
      >
        <mat-option
          *ngFor="let option of filterTypeOptions"
          [value]="option.value"
          [matTooltip]="option.help"
          matTooltipPosition="above"
        >
          {{ option.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- Quick actions -->
  <div id="quick-actions">
    <!-- TODO: Simplify conditionals -->
    <!-- Explore Collections with Object -->
<!--     <div
      *ngIf="element && showUsesInCollection"
    >
      <button mat-flat-button color="primary" [matMenuTriggerFor]="usedInCompilationsMenu"
              [disabled]="selectHistory.usedInCompilations.compilations.length === 0"
      >
        <mat-icon>playlist_add_check</mat-icon>
        {{selectHistory.usedInCompilations.compilations.length === 0
              ? "This object is not used in any collection."
              : "Explore collections with this object."}}
      </button>
    </div> -->
    <!-- Quick add entity to compilation -->
    <div
            *ngIf="(isAuthenticated$ | async) && isEntity(element)"
    >
      <!-- <button  mat-flat-button color="primary" [matMenuTriggerFor]="quickAddToCollectionMenu">
        <mat-icon>move_to_inbox</mat-icon>
        Use this object in a collection
      </button> -->
    </div>
  </div>

  <div id="rightButtons">

  <!-- Publishing option -->
  <div *ngIf="(isAuthenticated$ | async) && allowEditing && isEntity(element)">
    <button
      mat-flat-button
      *ngIf="!isPublished"
      color="accent"
      matTooltip="This object is currently hidden from the public. Click to publish!"
      (click)="togglePublished()"
    >
      <mat-icon>publish</mat-icon>
      Publish!
    </button>
    <button
      mat-flat-button
      *ngIf="isPublished && isEntity(element)"
      color="accent"
      matTooltip="This object is currently open to the public. Click to unpublish!"
      (click)="togglePublished()"
    >
      <mat-icon>flip_to_back</mat-icon>
      Unpublish
    </button>
  </div>
    <!-- Switch to annotation mode -->
    <div *ngIf="showAnnotateButton"
         [matTooltip]="allowAnnotating ? 'Annotate' : 'Only the object owner is allowed to annotate this object.'">
      <button
              mat-flat-button
              [disabled]="!allowAnnotating || !element" [routerLink]="annotateLink"
              color="accent"
              id="tib-logo"
      >
        <mat-icon>place</mat-icon>
        Annotate
      </button>
    </div>
    <div
            *ngIf="showEntityButton"
            matTooltip="Show entity"
    >
      <button 
              mat-flat-button
              [routerLink]="entityLink"
              color="accent"
              id="tib-logo"
      >
        <mat-icon>visibility</mat-icon>
        Show Entity
      </button>
    </div>

    <!-- Edit collection -->
    <div
            *ngIf="showEditButton && isCompilation(element) && allowEditing"
            matTooltip="Edit this collection."
    >
      <button mat-icon-button (click)="editCompilation()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <!-- Edit permissions -->
    <div
            *ngIf="showEditButton && !allowEditing"
            matTooltip="Only the object owner is able to edit settings of this object."
    >
      <button mat-icon-button disabled>
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <!-- Edit Entity settings -->
    <div
            *ngIf="showEditButton && isEntity(element) && allowEditing"
            matTooltip="Adjust the viewer settings, metadata or visibility of this object."
    >
      <button mat-icon-button [matMenuTriggerFor]="editMenu">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <!-- Copy iframe Link -->
    <div class="button-row" *ngIf="isEntity(element)">
      <button
              mat-flat-button
              matTooltip="Embed This Object on Your Web Page"
              (click)="copyEmbed(element.name.toString())"
      >
        <mat-icon>perm_media</mat-icon>
      </button>
      <!-- Download Metadata -->
      <button
              mat-flat-button
              matTooltip="Download Metadata"
              (click)="downloadMetadata()"
      >
        <mat-icon>list_alt</mat-icon>
      </button>
      <!-- Copy ID -->
      <button mat-flat-button matTooltip="Copy ID" (click)="copyId()">
        <mat-icon>fingerprint</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>

<mat-menu #usedInCompilationsMenu="matMenu">
  <button
    class="used-in-collections-button"
    *ngFor="let compilation of selectHistory.usedInCompilations.compilations"
    mat-menu-item
    (click)="navigate(compilation)"
  >
    <p style="margin: 0">
      <mat-icon matTooltip="View collection"> folder_special </mat-icon>
      <mat-icon
        *ngIf="isRecentlyAnnotated(compilation)"
        matTooltip="The current object has been recently annotated in this collection."
        >access_time</mat-icon
      >
      <mat-icon
        *ngIf="isAnnotatedInCompilation(compilation)"
        matTooltip="The current object is annotated in this collection."
      >
        label
      </mat-icon>
      {{ compilation.name }}
    </p>
  </button>
</mat-menu>

<mat-menu #editMenu="matMenu">
  <!-- TODO: Maybe change viewer mode? -->
  <button mat-menu-item (click)="editSettingsInViewer()">Viewer settings</button>
  <button mat-menu-item (click)="editMetadata()">Metadata</button>
  <button mat-menu-item (click)="editVisibility()">Visibility</button>
</mat-menu>

<mat-menu #quickAddToCollectionMenu="matMenu">
  <button class="quick-add-button" mat-button (click)="openCompilationWizard()">
    <mat-icon color="primary">create_new_folder</mat-icon>
    <span>New collection with this object</span>
  </button>
  <ng-container *ngIf="userCompilations.length > 0">
    <button
      class="quick-add-button"
      *ngFor="let compilation of userCompilations"
      mat-button
      (click)="quickAddToCompilation(compilation)"
    >
      {{ compilation.name }}
    </button>
  </ng-container>
</mat-menu>
