<app-actionbar [showEditButton]="true" [showEntityButton]="object ? true : false" [element]="entity"></app-actionbar>

<!-- No model ID provided: Annotation Overview -->
<section *ngIf="!object" id="annotation-overview">
  <div class="iframe-container entity-viewer fullheight">
    <iframe
      [src]="viewerUrl | safe: 'resourceUrl'"
      style="background-color: #111"
      allowfullscreen
    ></iframe>
  </div>
</section>

<!-- Model ID provided: Show viewer -->
<div *ngIf="object" id="wrap-annotate">
  <div id="annotation-viewer">
    <div class="iframe-container entity-viewer">
      <iframe [src]="viewerUrl | safe: 'resourceUrl'" allowfullscreen></iframe>
    </div>
  </div>

  <div id="annotation-data">
    <div class="detail-block">
      <h1 [innerHTML]="object.label['en']"></h1>
      <p [innerHTML]="object.description"></p>
    </div>
  </div>
</div>
