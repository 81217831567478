<form [formGroup]="form" (submit)="trySubmit()">
  <h3>Forgot your password?</h3>
  <p>You can request your password using your username<br />and will receive a mail!</p>
  <mat-form-field class="fullwidth">
    <input
      matInput
      placeholder="Enter your username"
      type="text"
      autocomplete="username"
      name="username"
      formControlName="username"
      required
    />
  </mat-form-field>

  <button mat-stroked-button type="submit" color="primary" [disabled]="!form.valid">
    Submit password request
  </button>
</form>

<p class="errors" *ngIf="serverErrorMsg.length > 0">
  {{ serverErrorMsg }}
</p>
