<form [formGroup]="uploadApplicationForm" (submit)="trySend()">
  <div class="metadata-nested">
    <h1>Upload application form</h1>
    <p>You currently do not have upload rights.</p>
    <p>
      Before being allowed to upload objects to the Semantic Kompakkt platform, we want to hear your
      motivations.
    </p>
  </div>

  <div class="metadata-nested">
    <h3>What's your motivation for using Semantic Kompakkt?</h3>
    <mat-form-field>
      <textarea
        matInput
        placeholder="Motivation"
        name="motivation"
        formControlName="motivation"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="metadata-nested">
    <div>
      <h3>Are you using Semantic Kompakkt as part of an institution or university?</h3>
      <span class="slider-container"> No <mat-slide-toggle #slider></mat-slide-toggle> Yes </span>
    </div>

    <div *ngIf="slider.checked" class="address-input">
      <mat-form-field class="fullwidth">
        <input
          matInput
          placeholder="University"
          type="text"
          name="university"
          formControlName="university"
        />
      </mat-form-field>
      <mat-form-field class="fullwidth">
        <input
          matInput
          placeholder="Institution"
          type="text"
          name="institution"
          formControlName="institution"
        />
      </mat-form-field>
      <div [formGroup]="address">
        <mat-form-field>
          <input
            matInput
            placeholder="Country"
            type="text"
            name="country"
            formControlName="country"
          />
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="City" type="text" name="city" formControlName="city" />
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            placeholder="Postal code"
            type="text"
            name="postcode"
            formControlName="postcode"
          />
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Street" type="text" name="street" formControlName="street" />
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Number" type="text" name="number" formControlName="number" />
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            placeholder="Building"
            type="text"
            name="building"
            formControlName="building"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="metadata-nested">
    <button mat-raised-button color="accent" type="submit">Submit request</button>
    <mat-error *ngIf="errorMsg">{{errorMsg}}</mat-error>
  </div>
</form>
