<div id="teaser-container">
  <div id="particles"></div>
  <div id="logo" [class.shown]="viewerLoaded$ | async">
    <iframe
      #viewerFrame
      [src]="viewerUrl | safe: 'resourceUrl'"
      class="display-fix"
      allowtransparency="true"
      allowfullscreen
      (load)="onViewerLoad($event)"
    ></iframe>
    <div>
      <img src="assets/semantic-kompakkt-logo-text.svg" alt="Kompakkt" />
      <p id="tib-string">annotate with things not strings</p>
    </div>
  </div>
  <div id="cards" #teaserCards>
    <div class="teaser-card" routerLink="/explore">
      <div class="teaser-head">
        <mat-icon>explore</mat-icon>
        <h1>Explore, share &amp; embed</h1>
      </div>
      <p>
        Semantic Kompakkt works with images, videos, audio files and 3D models. Explore them in 3D and become part of our community to share your own objects. It is all accessible in your browser. All uploaded objects can also be embedded on other websites via iframe.
      </p>
    </div>
    <div class="teaser-card" routerLink="/annotate">
      <div class="teaser-head">
        <mat-icon>place</mat-icon>
        <h1>Annotate</h1>
      </div>
      <p>
        Enrich multimedia objects by adding descriptions, references or semantic concepts to specific points in space. You can interlink objects via annotations or build collections with different access privileges. Explore annotations by others, annotate yourself or together with collaborators.
      </p>
    </div>
    <div class="teaser-card" routerLink="/explore">
      <div class="teaser-head">
        <mat-icon>people</mat-icon>
        <h1>Collaborate</h1>
      </div>
      <p>
        Objects, Annotations and Collections allow collaboration with other Semantic Kompakkt users. Use granular controls when choosing visibility and what to share with whom. In the spirit of collaboration, Semantic Kompakkt builds on open source tools and shares its code openly.
      </p>
    </div>

    <div id="controls">
      <mat-icon (click)="previousCard()">arrow_left</mat-icon>
      <mat-icon [class.active]="selectedCard % 3 === 0" (click)="setTeaserCard(0)"
        >explore</mat-icon
      >
      <mat-icon [class.active]="selectedCard % 3 === 1" (click)="setTeaserCard(1)">place</mat-icon>
      <mat-icon [class.active]="selectedCard % 3 === 2" (click)="setTeaserCard(2)"> people</mat-icon>
      <mat-icon (click)="nextCard()">arrow_right</mat-icon>
    </div>
  </div>
</div>

<!--app-actionbar></app-actionbar-->

<section class="content" id="kompakkt-previews">
  <div class="entity-grid">
    <app-grid-element
      *ngFor="let element of teaserEntities"
      class="grid-item"
      [element]="element"
    ></app-grid-element>
  </div>
</section>
